import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          describe: {
            company: {
              1: "Say goodbye to cumbersome searches through complex patent databases",
              2: "With our revolutionary AI-driven chat interface, you can now converse with patents effortlessly.",
              3: "Imagine having a patent expert right at your fingertips, available 24/7. Our cutting-edge AI, inspired by the remarkable Chat-GPT technology, transforms the way you search for patents.",
              4: "Engage in natural language conversations to unearth critical innovations, uncover hidden gems, and navigate the intricate world of intellectual property.",
            },
            team: {
              meet: "Meet the Team",
              1: "A Telecom SudParis and ENSAE alumni with extensive experience in the development industry, specializing in data science and data engineering. My expertise is grounded in both theoretical knowledge and practical application, making it a valuable asset in driving data-driven innovation and solving complex business challenges.",
              2: "A Telecom SudParis alumni who excels as a full-stack web developer and has a background in IT consulting with project management expertise. His educational foundation from Telecom SudParis provides a strong technical background, while his experience as an IT consultant has honed his project management and client engagement skills.",
            },
            intro : {
              at: "At ",
              1: "we are reshaping the future of patent exploration using the power of AI and natural language interaction.",
              2: "Imagine having a patent expert right at your fingertips, available 24/7.",
              3: "Our cutting-edge AI, inspired by the remarkable Chat-GPT technology, transforms the way you search for patents.",
            },
          },
          contact: {
            us: "Contact us",
            Us: "Contact Us",
            title: "Send us a message",
            first_name: "First Name*",
            last_name: "Last Name*",
            email: "Email*",
            phone_number: "Phone Number*",
            message: "Message*",
            send_message: "Send Message",
            feel_free: "Feel free to contact us :",
          },
          about: {
            about: "About",
          },
          home:{
            home: "Home",
            hero: {
              title: "AI-Powered Patent Search",
              subtitle: "Explore the World of Intellectual Property through Natural Language Conversations.",
              button: "Learn more",
            },
          },
          services: {
            title: "Our Services",
            subtitle: "We are deeply committed to the growth and success of our clients.",
            integration: {
              title: "Integration",
              description: "We offer a seamless integration of our solution into your IT framework, along with full customization for your unique needs.",
            },
            consulting: {
              title: "Consulting",
              description: "We provide a wide range of consulting services to help you achieve your business goals.",
            },
            developper: {
              title: "DevOps",
              description: "We will be at the forefront of AI and NLP advancements to continually enhance your services and adapt to changes in the patent landscape.",
            },
            infrastructure: {
              title: "Infrastructure",
              description: "We provide comprehensive support for the procurement, installation, and maintenance of the most suitable infrastructure (GPU) tailored to your requirements."
            },
          },
          footer: {
            rights: "All rights reserved",
          },
        },
      },
      fr: {
        translation: {
          describe: {
            company: {
              1: "Dites adieu aux recherches fastidieuses dans des bases de données de brevets complexes",
              2: "Grâce à notre interface de chat révolutionnaire pilotée par l'IA, vous pouvez maintenant converser avec des brevets sans effort.",
              3: "Imaginez avoir un expert en brevets à portée de main, disponible 24 heures sur 24, 7 jours sur 7. Notre IA de pointe, inspirée de la remarquable technologie Chat-GPT, transforme la façon dont vous recherchez des brevets.",
              4: "Engagez des conversations en langage naturel pour découvrir des innovations critiques, découvrir des perles rares et naviguer dans le monde complexe de la propriété intellectuelle.",
            },
            team: {
              meet: "Rencontrez l'équipe",
              1: "Un ancien élève de Telecom SudParis et ENSAE avec une vaste expérience dans l'industrie du développement, spécialisé dans la science et l'ingénierie des données. Mon expertise est ancrée à la fois dans les connaissances théoriques et dans l'application pratique, ce qui en fait un atout précieux pour stimuler l'innovation axée sur les données et résoudre des défis commerciaux complexes.",
              2: "Un ancien élève de Telecom SudParis qui excelle en tant que développeur Web full-stack et qui a une expérience en conseil informatique avec une expertise en gestion de projet. Ses bases éducatives de Telecom SudParis lui procurent une solide formation technique, tandis que son expérience en tant que consultant informatique a perfectionné ses compétences en gestion de projet et en engagement client.",
            },
            intro: {
              at: "Chez ",
              1: "nous remodelons l'avenir de l'exploration des brevets en utilisant la puissance de l'IA et l'interaction en langage naturel.",
              2: "Imaginez avoir un expert en brevets à portée de main, disponible 24 heures sur 24, 7 jours sur 7.",
              3: "Notre IA de pointe, inspirée de la remarquable technologie Chat-GPT, transforme la façon dont vous recherchez des brevets.",
            }
          },
          contact: {
            us: "Contactez-nous",
            Us: "Contactez-nous",
            title: "Envoyez-nous un message",
            first_name: "Prénom*",
            last_name: "Nom*",
            email: "Email*",
            phone_number: "Numéro de téléphone*",
            message: "Message*",
            send_message: "Envoyer le message",
            feel_free: "Contactez-nous :",
          },
          about: {
            about: "À propos",
          },
          home:{
            home: "Accueil",
            hero: {
              title: "Recherche de brevets pilotée par l'IA",
              subtitle: "Explorez le monde de la propriété intellectuelle grâce à des conversations en langage naturel.",
              button: "En savoir plus",
            },
          },
          services: {
            title: "Nos services",
            subtitle: "Nous sommes profondément engagés dans la croissance et le succès de nos clients.",
            integration: {
              title: "Intégration",
              description: "Nous offrons une intégration complète de notre solution dans votre infrastructure informatique, ainsi qu'une personnalisation complète pour vos besoins uniques.",
            },
            consulting: {
              title: "Consulting",
              description: "Nous proposons une large gamme de services de conseil pour vous aider à atteindre vos objectifs commerciaux.",
            },
            developper: {
              title: "DevOps",
              description: "Nous serons à l'avant-garde des avancées de l'IA et du NLP pour améliorer continuellement vos services et nous adapter aux changements du paysage des brevets.",
            },
            infrastructure: {
              title: "Infrastructure",
              description: "Nous fournissons un support complet pour l'approvisionnement, l'installation et la maintenance de l'infrastructure (GPU) la plus adaptée à vos besoins."
            },
          },
          footer: {
            rights: "Tous droits réservés",
          },
        },
      },
      
    },
  });

export default i18n;
