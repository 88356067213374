import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from "react-i18next";

const NavLinks = () => {
    const { t } = useTranslation();

    return (
        <>
            <HashLink className="px-4 font-semibold text-blue-dark hover:text-lime-600" smooth to="/">
                                {t("home.home", {
                    defaultValue:
                    "Home",
                })}
            </HashLink>
            <HashLink className="px-4 font-semibold text-blue-dark hover:text-lime-600" smooth to="/about">
                              {t("about.about", {
                defaultValue:
                  "About",
              })}
            </HashLink>
            <HashLink className="px-4 font-semibold text-blue-dark hover:text-lime-600" to="/contact">
                                {t("contact.Us", {
                    defaultValue:
                    "Contact Us",
                })}
            </HashLink>
        </>
    )
}

export default NavLinks;