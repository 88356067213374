import React from "react";
import blueMan from "../images/blue-man.svg";
import greenMan from "../images/green-man.svg";
import { useTranslation } from "react-i18next";

const Team = () => {
  const { t } = useTranslation();

  return (
    <div className="team m-auto p-2 md:p-12 h-5/6  w-screen">
      <h3
        className="text-3xl  text-blue-light 
                    font-bold text-center"
      >
        {t("describe.team.meet", {
          defaultValue: "Meet the Team",
        })}
      </h3>
      <div
        className="flex flex-col-reverse lg:flex-row py-8 pt-0 justify-between lg:text-left w-full"
        data-aos="zoom-out"
      >
        <div className="lg:w-1/2 flex flex-col lg:mx-4">
          <div>
            <img
              alt="card img"
              className="duration-1000 w-full"
              src={greenMan}
            />
          </div>
          <h3
            className="text-3xl  text-blue-light 
                    font-bold text-center"
          >
            A.D
          </h3>
          <div>
            <p className="text-xl text-blue-dark text-center">
              {t("describe.team.1", {
                defaultValue:
                  "A Telecom SudParis and ENSAE alumni with extensive experience in the development industry, specializing in data science and data engineering. My expertise is grounded in both theoretical knowledge and practical application, making it a valuable asset in driving data-driven innovation and solving complex business challenges.",
              })}
            </p>
          </div>
        </div>
        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
          <div>
            <img
              alt="card img"
              className="duration-1000 w-full"
              src={blueMan}
            />
          </div>
          <h3 className="text-3xl  text-blue-light font-bold text-center">
            Y.K
          </h3>
          <div>
            <p className="text-xl text-blue-dark text-center">
              {t("describe.team.2", {
                defaultValue:
                  "A Telecom SudParis alumni who excels as a full-stack web developer and has a background in IT consulting with project management expertise. His educational foundation from Telecom SudParis provides a strong technical background, while his experience as an IT consultant has honed his project management and client engagement skills.",
              })}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
