import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from "react-i18next";

const Footer = () => {
    const { t } = useTranslation();

    return (
            <footer>
                <div className="footer px-4 sm:px-6">
                    <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4 ">
                        <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
                            <div className="text-sm text-blue-dark py-1">
                                Copyright &copy; {new Date().getFullYear()}{"  "}
                                <HashLink
                                    to="#"
                                    className=" hover:text-green-lime"
                                >
                                    DoppleDoc
                                </HashLink>. {t("footer.rights", {
                                    defaultValue:
                                    "All rights reserved.",
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
    )
}
export default Footer;
