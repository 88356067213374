import React from "react";
import hosting from "../images/hosting.svg";
import consulting from "../images/consulting.svg";
import interfaceIntegration from "../images/interface-integration.svg";
import developper from "../images/developper.svg";
import { useTranslation } from "react-i18next";

const Services = () => {
  const { t } = useTranslation();
  
  return (
    <div id="services" className="bg-gray-100 py-12">
      <section data-aos="zoom-in-down">
        <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl text-lime-600 uppercase font-bold">
            {t("services.title", {
              defaultValue: "Our Services",
            })}
          </h2>

          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-lime-600"></div>
          </div>
          <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl text-blue-light">
            {t("services.subtitle", {
              defaultValue:
                "We are deeply committed to the growth and success of our clients.",
            })}
          </h2>
        </div>

        <div className="px-12" data-aos="fade-down" data-aos-delay="600">
          <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={interfaceIntegration}
                />
                <h2 className="my-4 text-2xl text-center text-lime-600 font-semibold">
                  {t("services.integration.title", {
                    defaultValue: "Integration",
                  })}
                </h2>
                <p className="text-md font-medium">
                  {t("services.integration.description", {
                    defaultValue:
                      "We offer a seamless integration of our solution into your IT framework, along with full customization for your unique needs.",
                  })}
                </p>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={developper}
                />
                <h2 className="my-4 text-2xl text-center text-lime-600 font-semibold">
                  {t("services.developper.title", {
                    defaultValue: "DevOps",
                  })}
                </h2>
                <p className="text-md font-medium">
                  {t("services.developper.description", {
                    defaultValue:
                      "We will be at the forefront of AI and NLP advancements to continually enhance your services and adapt to changes in the patent landscape.",
                  })}
                </p>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={hosting}
                />
                <h2 className="my-4 text-2xl text-center text-lime-600 font-semibold">
                  {t("services.infrastructure.title", {
                    defaultValue: "Infrastructure Services",
                  })}
                </h2>
                <p className="text-md font-medium">
                  {t("services.infrastructure.description", {
                    defaultValue:
                      "We provide comprehensive support for the procurement, installation, and maintenance of the most suitable infrastructure (GPU) tailored to your requirements.",
                  })}
                </p>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={consulting}
                />
                <h2 className="my-4 text-2xl text-center text-lime-600 font-semibold">
                  {t("services.consulting.title", {
                    defaultValue: "IT Consulting",
                  })}
                </h2>
                <p className="text-md font-medium">
                  {t("services.consulting.description", {
                    defaultValue:
                      "We offer custom AI solutions and consulting services for enterprises looking to integrate AI into their patent-related workflows.",
                  })}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
