import React from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import Team from '../components/Team';
import Company from '../components/Company';

const About = () => {


    return (
        <div className='flex flex-col h-screen' >
            <div>
                <NavBar />
            </div>
            <div className="flex flex-col justify-between h-full">
                <Company />
                <Team />
                <Footer />
            </div>
        </div>
    )
}

export default About;